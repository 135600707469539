<template>
  <div class="news_link">
    <router-link :to="{name: 'news-detail', params: { news: topic.id }}">
      <ul class="news_area">
        <li>
          <div class="news_text">{{ topic.subject }}</div>
          <div class="news_date">{{ publish_at | formatDate }}</div>
        </li>
        <li>
          <div class="link_arrow"><img src="@/assets/img/mypage/link_arrow.png" alt="矢印画像"></div>
        </li>
      </ul>
    </router-link>
  </div><!--.news_link-->
</template>

<script>
import moment from 'moment';

export default {
  name: 'NewsLink',
  props: {topic: Object},
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD');
    }
  },
  computed: {
    publish_at () {
      return this.topic.start_at || this.topic.created_at;
    }
  }
}
</script>
