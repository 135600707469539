<template>
  <div>

    <AppHeader theme="white">お知らせ</AppHeader>

    <section class="contents">

      <div class="contentInner pb_80">

        <NewsLink class="news_link" v-for="topic in news" :key="topic.id" :topic="topic"></NewsLink>
<!--        <div class="news_link" v-for="topic in news" :key="topic.id">-->
<!--          <router-link :to="{name: 'news-detail', params: { news: topic.id }}">-->
<!--            <ul class="news_area">-->
<!--              <li>-->
<!--                <div class="news_text">{{ topic.subject }}</div>-->
<!--                <div class="news_date">{{ topic.created_at | formatDate }}</div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="link_arrow"><img src="@/assets/img/mypage/link_arrow.png" alt="矢印画像"></div>-->
<!--              </li>-->
<!--            </ul>&lt;!&ndash; .news_area &ndash;&gt;-->
<!--          </router-link>-->
<!--        </div>&lt;!&ndash;.news_link&ndash;&gt;-->

      </div><!--.contentInner-->

    </section><!--.contents-->

    <AppFooter theme="yellow" fixed></AppFooter>

  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import NewsLink from '@/components/NewsLink';
export default {
  name: 'News',
  components: {AppHeader, AppFooter, NewsLink},
  props: { news: Array },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
